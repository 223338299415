.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vh; */
}

.Auth-form {
  margin-top: 200px;
  width: 600px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  background-color: white;
}
.Auth-register-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 100vw; */
  /* height: 100vh; */
  margin-top: 100px;
}
.Auth-register-form {
  width: 600px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-radius: 8px;
  background-color: white;
  /* margin-bottom: 100px; */
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 28px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 17px !important;
  font-weight: 500 !important;
  color: rgb(34, 34, 34);
}
button {
  outline: none;
  border: none;
  /* text-align: center; */
}
input:not([type="date"]) {
  /* border:1px solid rgba(0, 0, 0, 0.426) !important; */
  /* padding: 26px 20px !important; */
  background: #ffffff !important;
  /* background-color: red; */
}
.Auth-form input,
.Auth-register-form input {
  border: none !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.426) !important;
}
.project-form {
  margin: 0 40px;
}

.project-card-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;

  margin-bottom: 20px;
}
.project-card {
  background-color: white;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  /* line-height: 60px; */
}
.add-btn {
  background-color: #ef4444;
  color: white;
  padding: 10px 30px;
}
.error-msg {
  color: red;
}
.chartContainer {
  display: flex;
  width: 100%;
  gap: 50px;
  flex-wrap: wrap;
}
.chartContainer-print {
  display: flex;
  width: 100%;
  row-gap: 50px;
  flex-wrap: wrap;
  /* column-gap: 100px; */
}
.card-value {
  /* font-size: 25px !important; */
  font-weight: 900;
  color: #ef4444;
}
.card-label {
  font-size: 18px !important;
  font-weight: 900;
  border-bottom: 2px solid grey;
}
.detail-print-container {
  margin-top: -25px;
  padding-left: 20px;
}
.detail-header {
  font-size: 20px !important;
  font-weight: 900;
}
.detail-date {
  font-size: 17px !important;
  font-weight: 900;
  /* margin-top: 3px; */
}
.detail-field {
  display: flex;
  font-size: 15px !important;
  margin-top: 5px;
  font-weight: 500;
}
.detail-field > div:first-child {
  width: 300px;
}
.nav-input {
  /* height: 40px !important; */
  width: 400px;
  /* position: relative; */
}
.nav-input input {
  height: 100% !important;
  border: 1px solid gray;
  width: 100%;
  border-radius: 15px;
  padding: 10px;
}
/* .nav-input-icon{
  position:absolute;
  color: rebeccapurple;
  right: 15px;
  top: 6px;
  font-size: 25px;
} */
.date-container {
  display: flex;
  align-items: center;
  width: 800px;
  justify-content: flex-end;
}
.date-container > div {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-left: 5px;
}
.date-container > div > span {
  font-weight: 800;
}

.close-icon {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}

.close-icon button {
  color: white;
}

.responsive-table th,
.responsive-table td {
  padding: 1px;
  border: 0.5px solid #ccc;
  text-align: center;
}

.capitalize::first-letter {
  text-transform: uppercase;
}

.settings-nav{
  background-color: rgb(235, 235, 235);
}
.settings-btn:hover {
  background-color: rgb(242, 242, 242);
  color: black;
}
.settings-active {
  background-color: white;
  color: black;
}

.cursor-pointer {
    cursor: pointer !important;
}
.text-faded{
  color: #ccc;
}
