.myTable {
    .table>thead>tr>th {
        font-size: 14px;
        color: #222;
        font-weight: 600;
    }

    .react-bootstrap-table-pagination-list {
        display: flex;
        justify-content: end;
    }
}

.w-240 {
    max-width: 240px;
    min-width: 100px;
}

.h-240-min {
    max-height: 240px;
    overflow-y: auto;
}

.sensor-table {
    overflow-x: auto;

    .table>thead>tr>th {
        border: 1px solid #dee2e6 !important;
        text-align: center !important;
        color: #222;
        font-weight: 600;
    }

    .table>tbody>tr>td {
        text-align: center !important;
    }

    .table {
        min-width: 1525px;
    }
}

.gie-select input{
    opacity: 0 !important;
}
.sidebar .logo-img img{
    max-width: 100px !important;
    max-height: 100px !important;
}